import React from 'react'
import './remove.scss'
import $ from 'jquery'; 
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Return from '../navbar/Return';
export default function Remove() {
    const [data, setData] = useState([]);
    const [image, setRemoved] = useState("none");
    const [copy, setCopied] = useState("none");
    const [search, searchState] = useState("normal");
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const [imageCount, setCount] = useState(0);
    useEffect(() => {
        async function getImages() {
            try {
                const response = await fetch('https://www.api.kenankorn.com/data/images', {
                    method: 'GET',
                    headers: {
                      accept: 'application/json',
                    },
                  });
                  if (response.status != 200) {
                      setData('Error');
                  }
                  if (response.status == 200) {
                      const data = await response.json();
                      setData(data);
                      setCount(data.length);
                  }
            }catch(e){
                setData('error')
              }
          }
          getImages()
    }, [])

    function openImage(e) {
        var imageURL = e.target.innerHTML;
        window.open(imageURL)
    }
    function copyImage(e) {
        var url = e.target.id;
        navigator.clipboard.writeText(url);
        setCopied("copied")
        toast.success('Copied text!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    function deleteImage(e) {
        var postid = e.target.id;
        var div = e.target.parentNode;
        div.style.display = "none";
        var baseUrl = 'https://kenanphotography.s3.us-west-1.amazonaws.com/';
        var url = e.target.getAttribute("image-url");
        var imageTag = url.replace(baseUrl, "");         
        let body_data = {
            "id": postid,
            "tag": imageTag
        }
        async function deletePostData() {
            const response = await fetch("https://www.api.kenankorn.com/data/deleteimage", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            toast.success('Deleted image!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deletePostData()
    }
    function editImage(e){
        var postID = e.target.id;
        window.location.href = '/admin/edit/' + postID
    }
    function getImage(e) {
        var image = e.target.id;
        window.open(image, '_blank');
    }
    const handleChange = (e) => {
        const searchWord = e.target.value;
        setWordEntered(searchWord);
        const newFilter = data.filter((value) => {
          return value.name.toLowerCase().includes(searchWord.toLowerCase()) ||
          value.city.toLowerCase().includes(searchWord.toLowerCase()) ||
          value.country.toLowerCase().includes(searchWord.toLowerCase()) ||
          value.file_src.toLowerCase().includes(searchWord.toLowerCase()) ||
          value.collection.toLowerCase().includes(searchWord.toLowerCase());
        });
        if (searchWord === "") {
          setFilteredData([]);
          setRemoved("none")
          searchState("normal")
        } else {
            setRemoved("search")
          setFilteredData(newFilter);
          searchState("search")
        }
      }
  return (
    <div className='removeWrapper'>
        <div className="collectionsAdmin">
                <img src="https://kenanphotography.s3.us-west-1.amazonaws.com/_DSC7068-Edit.jpg" className='collectionsImage' id='headingImage' alt="header"></img>
                <div className="centered">
                    <h1 className="headerCollections">Remove Photos</h1>
                    <div className="subheaderCollections">
                        Remove and view photos here.
                        <br></br>
                        <div className="travel">
                            <a href="/admin/upload" className='uploadLink'>Upload Image</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="searchWrapper">
            <div className="searchCollections">
                <input type="text" className="searchBarCollections" placeholder='Search Images' onChange={handleChange}/>
            </div>
        </div>
        <h1 className="total">{imageCount} Total Images</h1>
        <table className='dataTable'>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Image</th>
                    <th>Collection</th>
                </tr>
                <></>
            {image === 'none' &&
                <>
                    {data.map((image, i) =>
                            <tr key={i}>
                                <td className='postID'>{image.id}</td>
                                <td className='locationClass'>{image.name}</td>
                                <td className='locationClass'>{image.city}, {image.country}</td>
                                <td className='imageClass'><img src={image.file_src} alt="preview" className='previewImage'/></td>
                                <td className='locationClass'>{image.collection}</td>
                                <div className="iconsMap">
                                    <i className="deleteIcon fa-solid fa-trash" id={image.id} image-url={image.file_src} onClick={deleteImage}></i>
                                    <i className="copyIcon fa-solid fa-copy" id={image.file_src} onClick={copyImage}></i>
                                    <i className="copyIcon fa-solid fa-pen" id={image.id} onClick={editImage}></i>
                                    <i className="copyIcon fa-solid fa-download" id={image.file_src} onClick={getImage}></i>
                                </div>
                                
                            </tr>
                        )}
                </>
            }
            {image === 'newdata' &&
                <>
                    <ToastContainer />
                    {data.map((image, i) =>
                            <tr key={i}>
                                <td className='postID'>{image.id}</td>
                                <td className='locationClass'>{image.name}</td>
                                <td className='locationClass'>{image.city}, {image.country}</td>
                                <td className='imageClass'><img src={image.file_src} alt="preview" className='previewImage' /></td>
                                <i className="deleteIcon fa-solid fa-trash" id={image.id} image-url={image.file_src} onClick={deleteImage}></i>
                                <td className='locationClass'>{image.collection}</td>
                                <i className="copyIcon fa-solid fa-copy" id={image.file_src} onClick={copyImage}></i>
                                <i className="copyIcon fa-solid fa-pen" id={image.file_src} onClick={editImage}></i>
                                <i className="copyIcon fa-solid fa-download" id={image.file_src} onClick={getImage}></i>
                            </tr>
                        )}
                </>
            }
            {search === "search" &&
                <>
                    {filteredData.map((image, i) =>
                            <tr key={i}>
                                <td className='postID'>{image.id}</td>
                                <td className='locationClass'>{image.name}</td>
                                <td className='locationClass'>{image.city}, {image.country}</td>
                                <td className='imageClass'><img src={image.file_src} alt="preview" className='previewImage' /></td>
                                <td className='locationClass'>{image.collection}</td>
                                <i className="deleteIcon fa-solid fa-trash" id={image.id} image-url={image.file_src} onClick={deleteImage}></i>
                                <i className="copyIcon fa-solid fa-copy" id={image.file_src} onClick={copyImage}></i>
                                <i className="copyIcon fa-solid fa-pen" id={image.id} onClick={editImage}></i>
                                <i className="copyIcon fa-solid fa-download" id={image.file_src} onClick={getImage}></i>
                            </tr>
                        )}
                </>
            }
            {copy === 'copied' &&
                <ToastContainer />
            }
            </table>
    </div>
  )
}

