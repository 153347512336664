import React, { useState } from 'react';
import './clientupload.scss';
import Side from '../../assets/headers/4.jpg';
import { CircularProgress } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function CreateClient() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [random, setRandom] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState({});
  const [edit, setEdit] = useState(false);
  const [clients, setClients] = useState([]);

  React.useEffect(() => {
    const getClients = async () => {
      try {
        const response = await fetch('https://www.api.kenankorn.com/admin/clients');
        if (!response.ok) {
          throw new Error('HTTP error ' + response.status);
        }
        const data = await response.json();
        setClients(data);
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }
    getClients();
  }
  , []);



  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('random', random);
    images.forEach((image, index) => {
      formData.append('images', image);
    });

    try {
      let url = 'https://www.api.kenankorn.com/client/post';

      if (edit) {
        url = `https://www.api.kenankorn.com/edit/client/post/${username}`;
      }

      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('HTTP error ' + response.status);
      }

      const data = await response.json();
      setUserData(data);
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Fetch error:', error);
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);

    const urls = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(urls);
  };

  return (
    <div className='client-upload-wrapper'>
      <div className="upload-form-main">
        <div className="client-left">
          <form onSubmit={handleSubmit} className='client-upload-form'>
            <div className="header-client-upload">
              <h1 className='header-main-client-form'>
                {edit ? 'Edit Client' : 'Create Client'}
              </h1>
              <div className="client-subtitle">
                {edit
                  ? 'Edit an existing client here. Ensure to upload all new images.'
                  : 'Create a client here. Ensure to upload all images.'}
              </div>
            </div>

            <div className="form-contents">
              <label>
                Username:
              </label>
              {!edit && 
                <input
                  type="text"
                  className="client-field"
                  name='client_name'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              }
              {edit && 
                <select
                  className="client-field"
                  name='client_name'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                >
                  {clients.map((client) => (
                    <option value={client.username}>{client.username}</option>
                  ))}
                </select>
                
              
              }
              

              {!edit && (
                <>
                  <div className="random-password">
                    Use random password:
                    <input
                      type="checkbox"
                      checked={random}
                      onChange={(e) => setRandom(e.target.checked)}
                    />
                  </div>

                  {!random && (
                    <>
                      <label>
                        Password:
                      </label>
                      <input
                        type="password"
                        className="client-field"
                        name='client-password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </>
                  )}
                </>
              )}

              <div className="images-wrapper">
                <label>
                  Images:
                </label>
                <input
                  type="file"
                  multiple
                  onChange={handleImageChange}
                  className='file-upload'
                  required
                />
              </div>
            </div>

            <input
              type="submit"
              value={edit ? 'Confirm Edit' : 'Create Client'}
              className='client-creation-button'
            />

            {!edit && 
                <button className="client-creation-button" onClick={() => setEdit(true)}>
                    Edit Client
                </button>
            }
            {edit && 
                <button className="client-creation-button" onClick={() => setEdit(false)}>
                    Create Client
                </button>
            }

            {success && (
              <div className="user-success-display">
                <div className="message">
                  User has been successfully {username ? 'updated' : 'created'}.
                </div>
                <div className="client-login-link">
                  Link: <a href={userData.loginUrl} target="_blank" rel="noreferrer" className='login-url'>{userData.loginUrl}</a>
                </div>
                <div className="client-password">
                  Password: <b>{userData.password}</b>
                </div>
              </div>
            )}
          </form>
        </div>
        <div className="client-right">
          <LazyLoadImage src={Side} alt="Paris view" className="client-right-image" />
        </div>
      </div>

      <div className="image-previews">
        <div className="image-grid">
          {previewImages.map((url, index) => (
            <div className="client-image-img" key={index}>
              <img src={url} alt="" className="client-image-img" />
            </div>
          ))}
        </div>
      </div>

      {loading && (
        <div className="loading-client">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
