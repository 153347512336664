import React, { useState } from 'react';
import {
    Drawer, List, ListItem, ListItemIcon, ListItemText,
    IconButton, Divider, useMediaQuery, useTheme, Button
  } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RemoveIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ForumIcon from '@mui/icons-material/Forum';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import './adminnav.scss'
import { useLocation } from 'react-router-dom';


const Adminnav = () => {
const theme = useTheme();
const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(!isMobile);  // Open by default on larger screens
  const toggleDrawer = (status) => () => {
    setOpen(status);
  };

  const logout = () => {
    localStorage.removeItem('token');
    // remove admin cookie 
    document.cookie = "admin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = '/login';
  }

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)} className='left-icon'>
        <MenuIcon />
      </IconButton>
      
      <Drawer anchor="left" 
        open={open} 
        onClose={toggleDrawer(false)} 
        PaperProps={{
          style: {
            width: 280,
            paddingTop: 20  // set the width of the drawer here
          },
        }}>
        <div className="drawer-header">
          <h2 className='admin-header-title'>Admin Panel</h2>
        </div>
        <List>
        <ListItem 
            button 
            component="a" 
            href="/admin"
            style={{ backgroundColor: location.pathname === "/admin" ? "#e0e0e0" : "transparent" }}  // Change #e0e0e0 to your desired color
        >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem button component="a" href="/admin/upload"
            style={{ backgroundColor: location.pathname === "/admin/upload" ? "#e0e0e0" : "transparent" }}  // Change #e0e0e0 to your desired color
          >
            <ListItemIcon>
              <CloudUploadIcon />
            </ListItemIcon>
            <ListItemText primary="Upload" />
          </ListItem>

          <ListItem button component="a" href="/admin/support"
            style={{ backgroundColor: location.pathname === "/admin/support" ? "#e0e0e0" : "transparent" }}  // Change #e0e0e0 to your desired color
          >
            <ListItemIcon>
              <ForumIcon />
            </ListItemIcon>
            <ListItemText primary="Support" />
          </ListItem>

          <ListItem button component="a" href="/admin/remove"
            style={{ backgroundColor: location.pathname === "/admin/remove" ? "#e0e0e0" : "transparent" }}  // Change #e0e0e0 to your desired color
          >
            <ListItemIcon>
              <RemoveIcon />
            </ListItemIcon>
            <ListItemText primary="Remove" />
          </ListItem>

          <Divider />

          <ListItem button component="a" href="/client/upload"
            style={{ backgroundColor: location.pathname === "/client/upload" ? "#e0e0e0" : "transparent" }}  // Change #e0e0e0 to your desired color
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Client" />
          </ListItem>

          <ListItem button component="a" href="/admin/client/dashboard"
            style={{ backgroundColor: location.pathname === "/admin/client/dashboard" ? "#e0e0e0" : "transparent" }}  // Change #e0e0e0 to your desired color
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Client Logs" />
          </ListItem>
        </List>
        <Divider />

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 'auto', padding: '1rem' }}>
            <Button 
                variant="contained"
                color="primary"
                startIcon={<LogoutIcon />}
                onClick={logout}
            >
                Logout
            </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default Adminnav;
