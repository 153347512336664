import React from 'react'
import './places.scss'
import Return from '../navbar/Return';

export default function Places() {
  return (
    <div className='placesWrapper'>
        <div className="collectionsHeader">
            <img src="https://kenanphotography.s3.us-west-1.amazonaws.com/DSC02001.jpg" className='collectionsImage' id='headingImage' alt="header"></img>
            <div className="centered">
                <h1 className="headerCollections">Write a Place Post</h1>
                <div className="subheaderCollections">
                    Write about a specific place
                </div>
            </div>
        </div>
        <br></br>
        <div className="placesForm">
            <iframe name="success" id="success">Success</iframe>
            <form method="POST" action="https://www.api.kenankorn.com/post/postplace" className="placeForm" target='success'>
                <input className="input" name='image' placeholder="Enter image"></input>
                <br></br><br></br>
                <input className="input" name='destination' placeholder="Enter destination"></input>
                <br></br><br></br>
                <input className="input" name='description' placeholder="Enter description"></input>
                <br></br><br></br>
                <input className="input" name='rating' placeholder="Enter ranking (/5)"></input>
                <br></br><br></br>
                <textarea name="content" className='input' id="" cols="30" rows="10" placeholder='Content'></textarea>
                <br></br><br></br>
                <input type="submit" value="Submit" className='submitPost'></input>
            </form>
        </div>
    </div>
  )
}
