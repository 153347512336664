import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Confetti from 'react-confetti';
import './modal.scss'; // Ensure this is styled for romance

const MarcelineModal = ({ open, handleClose, onSuccess }) => {
  const [yesStyle, setYesStyle] = useState({ fontSize: '1rem', padding: '8px 16px' });
  const [noStyle, setNoStyle] = useState({ fontSize: '1rem', padding: '8px 16px' });
  const [confetti, setConfetti] = useState(false);
  const [emojis, setEmojis] = useState([]);

  const handleNoClick = () => {
    setNoStyle((prevState) => ({
      fontSize: `${parseFloat(prevState.fontSize) - 0.1}rem`,
      padding: `${parseFloat(prevState.padding.split(' ')[0]) - 1}px ${parseFloat(prevState.padding.split(' ')[1]) - 2}px`
    }));
    setYesStyle((prevState) => ({
      fontSize: `${parseFloat(prevState.fontSize) + 0.1}rem`,
      padding: `${parseFloat(prevState.padding.split(' ')[0]) + 1}px ${parseFloat(prevState.padding.split(' ')[1]) + 2}px`
    }));
    setEmojis((prevEmojis) => [...prevEmojis, '😠']);
  };

  const handleYesClick = () => {
    setConfetti(true);
    setTimeout(() => {
      handleClose();
      setConfetti(false);
      onSuccess(true); // Notify the parent component of the success
    }, 4000); // Stop confetti after 5 seconds
  };

  return (
    <>
      {confetti && <Confetti />}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <h2 id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
            Marceline, will you be my Valentine? 💖
          </h2>
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <button style={yesStyle} onClick={handleYesClick} className='yes-button'>Yes 💖</button>
            <button style={noStyle} onClick={handleNoClick} className='no-button'>No 😠</button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MarcelineModal;
