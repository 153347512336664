// Marceline.js
import React, { useState, useEffect } from 'react';
import './marceline.scss'; // Make sure this path matches the location of your SCSS file
import MarcelineModal from './MarcelineModal';
import first from '../../assets/marceline/first.JPG';
import second from '../../assets/marceline/second.JPG';
import third from '../../assets/marceline/third.JPG';
import fourth from '../../assets/marceline/fourth.JPG';
import fifth from '../../assets/marceline/fifth.JPG';
import sixth from '../../assets/marceline/sixth.JPG';
import seventh from '../../assets/marceline/seventh.JPG';
import eighth from '../../assets/marceline/eighth.JPG';
import ninth from '../../assets/marceline/ninth.JPG';
import tenth from '../../assets/marceline/tenth.JPG';
import eleventh from '../../assets/marceline/eleventh.jpg';
import { ImageList, ImageListItem, Modal, Box, Typography, Button } from '@mui/material';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation
import Cookies from 'universal-cookie';
import Sound from 'react-sound';
import music from '../../assets/marceline/music.mp3';

const Marceline = () => {
  // Initialize state with the first message
  document.title = 'Marceline';
  const [message, setMessage] = useState("I have something to ask you...");
  const [showButton, setShowButton] = useState(false);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const images = [first, second, third, fourth, fifth, sixth, seventh, eighth, ninth, tenth, eleventh];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showFinalButton, setShowFinalButton] = useState(false);
  const [showImage, setShowImage] = useState(true);

    useEffect(() => {
        // Check for marceline cookie
        const cookies = new Cookies();
        const marcelineCookie = cookies.get('marceline');
    }, []);

  useEffect(() => {
    // Only set up the timeouts if there are more images to show
    if (currentImageIndex < images.length) {
      // First, fade out the current image after its display duration
      const fadeOutTimeoutId = setTimeout(() => {
        setShowImage(false);
      }, 3000); // Image display duration
  
      // Then, after the fade-out animation completes, move to the next image
      const nextImageTimeoutId = setTimeout(() => {
        const nextIndex = currentImageIndex + 1;
        if (nextIndex < images.length) {
          setCurrentImageIndex(nextIndex); // Move to the next image
          setShowImage(true); // Ready to start showing the next image
        } else {
          setShowFinalButton(true); // After the last image, show the final button
        }
      }, 6000); // Total duration before showing the next image (display + fade-out time)
  
      // Clean up the timeouts if the component unmounts or the currentImageIndex changes
      return () => {
        clearTimeout(fadeOutTimeoutId);
        clearTimeout(nextImageTimeoutId);
      };
    }
  }, [currentImageIndex, images.length]); // Depend on currentImageIndex and images.length
  

  const handleSuccess = (isSuccess) => {
    setSuccess(isSuccess);
    // You can perform additional actions here based on the success
    console.log("Success:", isSuccess);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showModal = () => {
    console.log("clicked");
    setModal(true);
  };

  const handleOpenSuccess = () => {
    setShowOverlay(true);
    cycleText();
  };

  useEffect(() => {

    const timer3 = setTimeout(() => {
      setShowButton(true);
    }, 4000);

    // Cleanup function
    return () => clearTimeout(timer3);
  }, []);

  const [showOverlay, setShowOverlay] = useState(false);
  const [textIndex, setTextIndex] = useState(0);
  const navigate = useNavigate(); // For navigation after the final text

  const messages = [
    "This is so worth it",
    "I hope you are not disappointed!",
    "This is what you were waiting for!",
    "Yayyy!",
    "I hope that you are in private...",
    "Remember what you said???",
    "Here it is..."
  ];



  const cycleText = () => {
    setTextIndex(0);
    const interval = setInterval(() => {
      setTextIndex((currentTextIndex) => {
        const nextIndex = currentTextIndex + 1;
        if (nextIndex === messages.length) {
          clearInterval(interval);
          setTimeout(() => {
            window.location.href = "https://www.pornhub.com/gay/video/search?search=chinese+lesbian+gay"
          }, 2000); // Wait a bit before redirecting
          return currentTextIndex;
        }
        return nextIndex;
      });
    }, 4000); // Change text every 4 seconds
  };

    const handleSongLoading = () => {
        console.log("Song is loading");
    }

    const handleSongPlaying = () => {
        console.log("Song is playing");
    }



  return (
    <div className='marceline-wrapper'>
         {showOverlay && (
            <div className="overlay">
            <h1 className="overlay-text">{messages[textIndex]}</h1>
            </div>
        )}
        {showFinalButton && !showOverlay &&
            <Sound
                url={music}
                playStatus={Sound.status.PLAYING}
                playFromPosition={25}
                onLoading={handleSongLoading}
                onPlaying={handleSongPlaying}
            />
        }
        {!success && 
        <>
            <div className="title-wrapper">
                <h1 className='name'>Hello 虞佳许!</h1>
                <h2 className='message'>{message}</h2>
                <button 
                    onClick={showModal} 
                    className='modal-trigger'
                    style={{ display: showButton ? 'block' : 'none' }} // Directly control display property based on showButton
                >
                    Guess what? 
                </button>
            </div>
            
            {modal && (
                <MarcelineModal open={modal} handleClose={handleClose} onSuccess={handleSuccess} />
            )}
        </>
        }
        {success && !showFinalButton && (
                <div className="image-overlay" key={currentImageIndex}>
                <img
                    src={images[currentImageIndex]}
                    alt={`Memory ${currentImageIndex + 1}`}
                    className={`overlay-image ${showImage ? 'fade-in' : 'fade-out'}`}
                />
                </div>
            )}

        {showFinalButton && (
        <div className="success-wrapper">
            <Confetti />
            <div className="title-wrapper">
                <h1 className='name'>She Said Yes!!!</h1>
                {showButton && (
                <button onClick={handleOpenSuccess} className='modal-trigger'>
                    A Little Gift!
                </button>
                )}
            </div>
            
        </div>
        )}
      
    </div>
  );
};

export default Marceline;
