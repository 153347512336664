import React from 'react'
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Return from '../navbar/Return';
export default function Blogedit() {
    const [data, setData] = useState([]);
    const [admin, setAdmin] = useState([]);
    const [postcount, setPost] = useState(5);
    const [deleted, setDeleted] = useState("none");
    useEffect(() => {
        async function getPlaces() {
            try {
                const response = await fetch('https://www.api.kenankorn.com/users/viewsignups', {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                    },
                    });
                    if (response.status != 200) {
                        setData('Error');
                    }
                    if (response.status == 200) {
                        const data = await response.json();
                        setData(data);
                    }
            }catch(e){
                setData('error')
                }
            }
            getPlaces()
        async function getAdmin() {
            try {
                const response = await fetch('https://www.api.kenankorn.com/admin/users', {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                    },
                    });
                    if (response.status != 200) {
                        setData('Error');
                    }
                    if (response.status == 200) {
                        const data = await response.json();
                        setAdmin(data);
                    }
            }catch(e){
                setData('error')
                }
            }
            getAdmin()
    }, [])
    function removeUser(e) {
        var email = e.target.id;
        var div = e.target.parentNode;
        div.style.display = "none";
        let body_data = {
            "email": email,
        }
        async function deletePostData() {
            const response = await fetch("https://www.api.kenankorn.com/removeuser", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            setDeleted("newdata")
            toast.success('Removed user!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deletePostData()
    }
    function removeAdmin(e) {
        var email = e.target.id;
        var div = e.target.parentNode;
        div.style.display = "none";
        let body_data = {
            "email": email,
        }
        async function deletePostData() {
            const response = await fetch("https://www.api.kenankorn.com/admin/removeuser", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            toast.success('Removed admin!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deletePostData()
    }
    function approveUser(e) {
        var email = e.target.getAttribute("user");
        var password = e.target.getAttribute("pass");
        var div = e.target.parentNode;
        div.style.display = "none";
        let body_data = {
            "email": email,
            "password": password
        }
        async function deletePostData() {
            const response = await fetch("https://www.api.kenankorn.com/approve", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            setDeleted("newdata")
            toast.success('Approved user!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deletePostData()
    }
  return (
    <div className='viewsWrapper'>
        <div className="collectionsHeader">
            <img src="https://nomadparadise.com/wp-content/uploads/2020/05/beautiful-places-005-1024x640.jpg.webp" className='collectionsImage' id='headingImage' alt="header"></img>
            <div className="centered">
                <h1 className="headerCollections">See Admin Requests</h1>
                <div className="subheaderCollections">
                    Approve/remove admin requests
                </div>
            </div>
        </div>
        <br></br>
            <>
            <table>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                </tr>
                <></>
            {deleted === 'none' &&
                <>
                    {data.slice(0,postcount).map((blog, i) =>
                            <tr key={i}>
                                <td className='postID'>{blog.name}</td>
                                <td className='locationClass'>{blog.email}</td>
                                <i className="approveIcon fa-solid fa-square-check" user={blog.email} pass={blog.password} onClick={approveUser}></i>
                                <i className="removeIcon fa-solid fa-square-xmark" id={blog.email} onClick={removeUser}></i>
                            </tr>
                        )}
                </>
            }
            {deleted === 'newdata' &&
                <>
                    <ToastContainer />
                    {data.slice(0,postcount).map((blog, i) =>
                            <tr key={i}>
                                <td className='postID'>{blog.name}</td>
                                <td className='locationClass'>{blog.email}</td>
                                <i className="approveIcon fa-solid fa-square-check" id={blog.id} onClick={approveUser}></i>
                                <i className="removeIcon fa-solid fa-square-xmark" id={blog.id} onClick={removeUser}></i>
                            </tr>
                        )}
                </>
            }
            </table>
            <br></br>
            <table>
                <tr>
                    <th>Email</th>
                </tr>
                <></>
            {deleted === 'none' &&
                <>
                    {admin.slice(0,postcount).map((admin, i) =>
                            <tr key={i}>
                                <td className='postID'>{admin.email}</td>
                                <i className="removeIcon fa-solid fa-square-xmark" id={admin.email} onClick={removeAdmin}></i>
                            </tr>
                        )}
                </>
            }
            </table>

            </>
    </div>
  )
}

