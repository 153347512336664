import {useState, useEffect} from 'react';
import * as React from 'react';
import $ from 'jquery'; 
import jQuery from 'jquery';
import './App.scss';
import Navbar from './components/navbar/Navbar'
import Gallery from './components/gallery/Gallery'
import Destinationedit from './components/admin/Destinationedit'
import Collectionsmake from './components/admin/Collectionsmake'
import { Alert } from '@mui/material';
import Password from './components/client/Password'
import AdminLayout from './pages/Adminlayout';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";
import Admin from './components/admin/Admin';
import List from './components/admin/List';
import Write from './components/write/Write';
import Login from './components/login/Login';
import Clientupload from './components/upload/Clientupload';
import Cookies from 'universal-cookie';

import Error from './components/error/Error';

import Placesedit from './components/admin/Placesedit'
import Approve from './components/admin/Approve'
import Upload from './components/upload/Upload';
import Remove from './components/upload/Remove';
import Adminnav from './components/navbar/Adminnav';
import Views from './components/admin/Views';
import Blogedit from './components/admin/Blogedit';
import About from './components/about/About';
import Places from './components/admin/Places';
import Maintenance from './components/error/Maintenance'
import Blocked from './components/error/Blocked'
import Loader from './components/loader/Loader';
import Client from './components/client/Client';
import Dashboard from './components/client/Dashboard';
import Editimage from './components/upload/Editimage';
import Marceline from './components/marceline/Marceline';
import Entry from './components/marceline/Entry';
import Support from './components/admin/Support';
const cookies = new Cookies();
function App() {
  const checkCookie = cookies.get('adminCookie');
  $("body").on("contextmenu", "img", function(e) {
    return false;
  });
  $("body").on('mousedown','img',function(e){
    e.stopPropagation();
    e.preventDefault();
});
const [open, setOpen] = React.useState(false);
  function showSnack() {
    setTimeout(
      function() {
        showFeedback()
      }, 5000);
  }
  function showFeedback() {
    setOpen(true);
    function setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      document.cookie = "showFeedback" + "=" + "true" + ";" + expires + ";path=/";
    }
    setCookie()
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    let sessionStartTime = new Date().getTime();

    async function checkToken() {
      let token = localStorage.getItem('token');
      let checkFeedback = cookies.get('showFeedback');
      if (checkFeedback) {
        setOpen(true);
      } else {
        showSnack();
      }
      try {
        const response = await fetch('https://www.api.kenankorn.com/check-token', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      
        const data = await response.json();
        if (data.token) {
          token = data.token;
          localStorage.setItem('token', token);
        }    
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }

    async function sendInitialLocation() {
      let token = localStorage.getItem('token');
    
      try {
        const response = await fetch('https://www.api.kenankorn.com/pagevisit', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      
        const data = await response.json(); 
        if (data.message === "New token issued") {
          token = data.token;
          localStorage.setItem('token', token);
        }
          
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }    
    


    // check if admin cookie exists
    let checkAdmin = cookies.get('adminCookie');
    if (!checkAdmin) {
        checkToken().then(() => {
            sendInitialLocation();
        });
    }
    //checkToken();

  }, []); 

  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Gallery /> }/>
        <Route exact path="/home" element={<Gallery /> }/>
        <Route path="/login" element={checkCookie ? <Admin /> : <Login />}/>
        <Route path="*" element={<Error />} />
        <Route path="/loading" element={<Loader />} />
        <Route path="/blocked" element={<Blocked />} />
        <Route path="/client/dashboard" element={<Client /> }/>
        <Route exact path="/client/login" element={<Password /> }/>
        <Route path="/marceline" element={<Entry /> }/>

        <Route path="/" element={<AdminLayout />} >
          <Route path="/admin" element={checkCookie ? <Views /> : <Login />}/>
          <Route path="/client/upload" element={checkCookie ? <Clientupload /> : <Login />}/>
          <Route path="/admintest" element={checkCookie ? <Admin /> : <Login />}/>
          <Route path="/admin/upload" element={checkCookie ? <Upload /> : <Login />}/>
          <Route path="/admin/support" element={checkCookie ? <Support /> : <Login />}/>
          <Route path="/admin/remove" element={checkCookie ? <Remove /> : <Login />}/>
          <Route path="/admin/edit/:id" element={checkCookie ? <Editimage /> : <Login />}/>
          <Route path="/admin/collections" element={checkCookie ? <Collectionsmake /> : <Login />}/>
          <Route path="/admin/client/dashboard" element={checkCookie ? <Dashboard /> : <Login />}/>
          <Route exact path="/admin/write" element={checkCookie ? <Write /> : <Login />}/>
          <Route exact path="/admin/views" element={checkCookie ? <Views /> : <Login />}/>
          <Route exact path="/admin/blogedit" element={checkCookie ? <Blogedit /> : <Login />}/>
          <Route exact path="/admin/approval" element={checkCookie ? <Approve /> : <Login />}/>
          <Route exact path="/admin/places" element={checkCookie ? <Places /> : <Login />}/>
          <Route exact path="/admin/placesedit" element={checkCookie ? <Placesedit /> : <Login />}/>
          <Route exact path="/admin/destinationedit" element={checkCookie ? <Destinationedit /> : <Login />}/>
          <Route path="/admin/list" element={checkCookie ? <List /> : <Login />}/>
        </Route>
      </Routes>
    </BrowserRouter>

    </div>
  );
}

export default App;
