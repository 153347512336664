import React from 'react'
import '../upload/upload.scss'
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Return from '../navbar/Return';
export default function Upload() {
    const [success, setSuccess] = useState("none");
    const [loading, setLoading] = useState("none");
    const [data, setData] = useState([]);
    const [file, setFile] = useState();
    const [name, setName] = useState("");
    const [dbname, setDbname] = useState("");
    const [removed, setRemoved] = useState("none");
    useEffect(() => {
        // 
        async function getCollections() {
            try {
                const response = await fetch('https://www.api.kenankorn.com/images/collections/all', {
                    method: 'GET',
                    headers: {
                      accept: 'application/json',
                    },
                  });
                  if (response.status != 200) {
                      setData('Error');
                  }
                  if (response.status == 200) {
                      const data = await response.json();
                      console.log(data)
                      if (data.length > 0) {
                        setData("No-data");
                      }
                      setData(data);
                  }
            }catch(e){
                setData('error')
              }
          }
          getCollections()
    }, [])
    function showPreview(e) {
        var imageInput = document.getElementById("imageInput");
        var image = document.getElementById("previewImage");
        var size = document.getElementById("imageSize");
        const [imageFile] = imageInput.files
        if (imageFile) {
            image.src = URL.createObjectURL(imageFile)
            size.innerHTML = imageFile.size + " mb"
        }
        setFile(e.target.files[0]);
    }
    let handleSubmit = async (e) => {
        setSuccess("loading")
        console.log(file)
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('dbname', dbname);
        formData.append('file', file);
        try {
            let res = await fetch("https://www.api.kenankorn.com/upload/collection", {
                method: "POST",
                body: formData,
        })
        let resJson = await res.json();
        if (res.status === 200) {
            showSuccess()
        } else {
            showError()
        }
        } catch (err) {
            showError()
        }
    };
    function showSuccess() {
        setSuccess("success")
        toast.success('Successfully posted!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    function showError() {
        setSuccess("error")
        toast.error('Error uploading image!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    function clearForm() {
        var form = document.getElementById("uploadForm");
        form.reset();
        setSuccess("")
        var image = document.getElementById("previewImage");
        image.reset();
    }
    function deleteImage(e) {
        var postid = e.target.id; 
        var div = e.target.parentNode;
        div.style.display = "none";   
        let body_data = {
            "id": postid,
        }
        async function deletePostData() {
            const response = await fetch("https://www.api.kenankorn.com/collections/delete", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            toast.success('Deleted image!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deletePostData()
    }
  return (
    <div className='uploadWrapper'>
        <div className="uploadForm">
            <img src="https://archive.org/download/placeholder-image/placeholder-image.jpg" alt="preview" id='previewImage' className='previewImage'/>
            <span className="fileDescriptor">File size: </span><span className="imageSize" id='imageSize'></span>
            <br></br><br></br>
            <form onSubmit={handleSubmit} className='upload' id='uploadForm'>
                <input type="file" name="file" accept='image/*' className='fileUpload' id='imageInput' onChange={showPreview}/>
                <br></br><br></br>
                <input type="text" className="inputUpload" name='name' placeholder='Collection name' onChange={(e) => setName(e.target.value)}/>
                <br></br><br></br>
                <input type="text" className="inputUpload" name='dbname' placeholder='DB Name' onChange={(e) => setDbname(e.target.value)}/>
                <br></br><br></br>
                <input type="submit" value="Upload" className='submitUpload'/>
            </form> 
        </div>
       
        {success === 'loading' &&
             <div className="loadingShow">
                File is uploading
                <br></br>
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        }
        {success === 'success' &&
            <>
            <ToastContainer />
            <div className="successDiv">
                <i className="checkMark fa-solid fa-circle-check"></i>Your image has been uploaded!
                <br></br>
                <button className="clearForm" onClick={clearForm}>Upload Another</button>
            </div>
            </>
        }
        {success === 'error' &&
            <>
            <ToastContainer />
            <div className="successDiv">
                <i className="checkMark fa-solid fa-x-mark"></i>Error uploading image.
            </div>
            </>
        }
        <br></br>
        <div className="tableWrapper">
        <table className='dataTable'>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>DB Name</th>
                <th>Image</th>
            </tr>
            
            {data != "No-data" && removed === "none" && 
            <>
                {data != "No-data" && data.map((image, i) =>
                    <tr key={i}>
                        <td className='postID'>{image.id}</td>
                        <td className='locationClass'>{image.name}</td>
                        <td className='locationClass'>{image.dbname}</td>
                        <td className='imageClass'><img src={image.image} alt="preview" className='previewImage' /></td>
                        <i className="deleteIcon fa-solid fa-trash" id={image.id} image-url={image.file_src} onClick={deleteImage}></i>
                    </tr>
                )}
            </>
            }
            {removed === "newdata" &&
            <>
                {data.map((image, i) =>
                    <tr key={i}>
                        <td className='postID'>{image.id}</td>
                        <td className='locationClass'>{image.name}</td>
                        <td className='locationClass'>{image.dbname}</td>
                        <td className='imageClass'><img src={image.image} alt="preview" className='previewImage' /></td>
                        <i className="deleteIcon fa-solid fa-trash" id={image.id} image-url={image.file_src} onClick={deleteImage}></i>
                    </tr>
                )}
            </> 
            }
        </table>
        </div>
        
    </div>
  )
}


