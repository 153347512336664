import React from 'react'
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Return from '../navbar/Return';
import Writerankings from '../writerankings/Writerankings';
export default function Blogedit() {
    const [destinations, setDestinations] = useState([]);
    const [rating, setRemoveRating] = useState("none");
    const [success, setSuccess] = useState("none");
    function showSuccess() {
        setSuccess("success")
        toast.success('Successfully posted!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

  return (
    <div className='viewsWrapper'>
        <div className="collectionsHeader">
            <img src="https://img.delicious.com.au/N4HZuhK0/del/2018/12/seljalandsfoss-iceland-98669-2.jpg" className='collectionsImage' id='headingImage' alt="header"></img>
            <div className="centered">
                <h1 className="headerCollections">Write a Destinations Post</h1>
                <div className="subheaderCollections">
                    Write and check destinations info!
                </div>
            </div>
        </div>
        <br></br>
            <>
            <iframe name="success" id="success"></iframe>
        <form method="POST" action="https://www.api.kenankorn.com/collections/topfive" className="writeForm" target='success' onSubmit={showSuccess}>
            <h1 className="rankings">Ranking 1</h1>
            <br></br>
            <input className="enterLink" name='id' placeholder="Enter id"></input>
            <br></br><br></br>
            <input className="enterLink" name='imageone' placeholder="Enter image"></input>
            <br></br><br></br>
            <input className="enterLink" name='categoryoneone' placeholder="Enter category"></input> <input className="enterLink" name='ratingoneone' placeholder="Enter rating"></input>
            <br></br><br></br>
            <input className="enterLink" name='categorytwoone' placeholder="Enter category"></input> <input className="enterLink" name='ratingtwoone' placeholder="Enter rating 2"></input>
            <br></br><br></br>
            <input className="enterLink" name='categorytwothree' placeholder="Enter category"></input> <input className="enterLink" name='ratingthreeone' placeholder="Enter rating 3"></input>
            <br></br><br></br>
            <input className="enterTitle" name='place1' placeholder="Enter location"></input>
            <br></br><br></br>
            <textarea className="enterTitle" name='descriptionlong1' placeholder="Enter long description"></textarea>
            <br></br><br></br>
            <h1 className="rankings">Ranking 2</h1>
            <br></br>
            <input className="enterLink" name='imagetwo' placeholder="Enter image"></input>
            <br></br><br></br>
            <input className="enterLink" name='categoryonetwo' placeholder="Enter category"></input> <input className="enterLink" name='ratingonetwo' placeholder="Enter rating"></input>
            <br></br><br></br>
            <input className="enterLink" name='categorytwotwo' placeholder="Enter category"></input> <input className="enterLink" name='ratingtwotwo' placeholder="Enter rating 2"></input>
            <br></br><br></br>
            <input className="enterLink" name='categorythreetwo' placeholder="Enter category"></input> <input className="enterLink" name='ratingthreetwo' placeholder="Enter rating 3"></input>
            <br></br><br></br>
            <input className="enterTitle" name='place2' placeholder="Enter location"></input>
            <br></br><br></br>
            <textarea className="enterTitle" name='descriptionlong2' placeholder="Enter long description"></textarea>
            <br></br><br></br>
            <h1 className="rankings">Ranking 3</h1>
            <br></br>
            <input className="enterLink" name='imagethree' placeholder="Enter image"></input>
            <br></br><br></br>
            <input className="enterLink" name='categoryonethree' placeholder="Enter category"></input> <input className="enterLink" name='ratingonethree' placeholder="Enter rating"></input>
            <br></br><br></br>
            <input className="enterLink" name='categorytwothree' placeholder="Enter category"></input> <input className="enterLink" name='ratingtwothree' placeholder="Enter rating 2"></input>
            <br></br><br></br>
            <input className="enterLink" name='categorythreethree' placeholder="Enter category"></input> <input className="enterLink" name='ratingthreethree' placeholder="Enter rating 3"></input>
            <br></br><br></br>
            <input className="enterTitle" name='place3' placeholder="Enter location"></input>
            <br></br><br></br>
            <textarea className="enterTitle" name='descriptionlong3' placeholder="Enter long description"></textarea>
            <br></br><br></br>
            <h1 className="rankings">Ranking 4</h1>
            <br></br>
            <input className="enterLink" name='imagefour' placeholder="Enter image"></input>
            <br></br><br></br>
            <input className="enterLink" name='categoryonefour' placeholder="Enter category"></input> <input className="enterLink" name='ratingonefour' placeholder="Enter rating"></input>
            <br></br><br></br>
            <input className="enterLink" name='categorytwofour' placeholder="Enter category"></input> <input className="enterLink" name='ratingtwofour' placeholder="Enter rating 2"></input>
            <br></br><br></br>
            <input className="enterLink" name='categorythreefour' placeholder="Enter category"></input> <input className="enterLink" name='ratingthreefour' placeholder="Enter rating 3"></input>
            <br></br><br></br>
            <input className="enterTitle" name='place4' placeholder="Enter location"></input>
            <br></br><br></br>
            <textarea className="enterTitle" name='descriptionlong4' placeholder="Enter long description"></textarea>
            <br></br><br></br>
            <h1 className="rankings">Ranking 5</h1>
            <br></br>
            <input className="enterLink" name='imagefive' placeholder="Enter image"></input>
            <br></br><br></br>
            <input className="enterLink" name='categoryonefive' placeholder="Enter category"></input> <input className="enterLink" name='ratingonefive' placeholder="Enter rating"></input>
            <br></br><br></br>
            <input className="enterLink" name='categorytwofive' placeholder="Enter category"></input> <input className="enterLink" name='ratingtwofive' placeholder="Enter rating 2"></input>
            <br></br><br></br>
            <input className="enterLink" name='categorythreefive' placeholder="Enter category"></input> <input className="enterLink" name='ratingthreefive' placeholder="Enter rating 3"></input>
            <br></br><br></br>
            <input className="enterTitle" name='place5' placeholder="Enter location"></input>
            <br></br><br></br>
            <textarea className="enterTitle" name='descriptionlong5' placeholder="Enter long description"></textarea>
            <br></br><br></br>
            <input type="submit" value="Submit" className='submitWrite'></input>
        </form>
        {success === 'success' &&
            <>
            <ToastContainer />
            <div className="successDiv">
                <i className="checkMark fa-solid fa-circle-check"></i>Your blog post has been uploaded!
            </div>
            </>
        }
            </>
    </div>
  )
}

