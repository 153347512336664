import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Header from './header/Header';
import Loader from '../loader/Loader';
import Footer from '../footer/Footer';
import { Alert } from '@mui/material';
import SwipeIcon from '@mui/icons-material/Swipe';
import SwipeDownIcon from '@mui/icons-material/SwipeDown';
import './gallery.scss';
import './collection.scss';
import LoaderSpinner from './loader-temp/LoaderSpinner';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Gallery = (props) => {
  const [display, setHeaderDisplay] = useState(false);
  const [showInsta, setShowInsta] = useState(false);
  const [images, setImages] = useState('all');
  const [filterstate, setStateFilter] = useState('initial');
  const [filter, setFilter] = useState('all');
  const [modal, setModal] = useState('none');
  const [retrievedcountry, setRetrieved] = useState([]);
  const [countrydata, setCountries] = useState('');
  const [webpackage, setPackage] = useState('');
  const [data, setData] = useState([]);
  const [cities, setCities] = useState([]);
  const [collection, setCollection] = useState([]);
  const [collectiondisplay, setDisplay] = useState("normal");
  const [cityfilter, setCityFilter] = useState('');
  const [mode, setMode] = useState('light');
  const [state, setState] = useState('gallery');
  const [count, setCount] = useState(0);
  const [load, setLoaded] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSmallScreen, setIsSmallScreen] = useState(screenWidth < 785);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [collectionfilter, setCollectionFilter] = useState('');
  const [skeleton, setSkeleton] = useState(false);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(80);
  const [allImages, setAllImages] = useState([]);
  const [loadedImages, setLoadedImages] = useState([]);
  
  const isFirstSlide = currentIndex === 0;

  const renderCenterLeftControls = () =>
    isFirstSlide ? <div className="message">Hello, this is the first slide!</div> : null;

  const renderCenterRightControls = () => null;

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6
  };

  useEffect(() => {
    //scroll to top of page automatically
    window.scrollTo(0, 0);
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Instagram')) {
      setShowInsta(true)
    }

    function handleResize() {
      setScreenWidth(window.innerWidth);
      setIsSmallScreen(screenWidth < 500);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenWidth]);

  

  useEffect(() => {
    document.addEventListener('contextmenu', event => event.preventDefault());

    var settings = localStorage.getItem('site_config');
    if (settings === 'single') {
      setState("single");
    }
    if (settings === 'gallery') {
      setState("gallery");
    }
    if ($(window).width() < 480) {
      setState('gallery');
    }

    async function getImages() {
        // ...
        try {
          const response = await fetch('https://www.api.kenankorn.com/data/images', {
            method: 'GET',
            headers: {
              accept: 'application/json',
            },
          });
      
          if (response.status !== 200) {
            setData('Error');
          }
          if (response.status === 200) {
            const data = await response.json();
            data.sort(() => Math.random() - 0.5);
            setData(data);
            // Calculate the total number of pages
            const total = Math.ceil(data.length / perPage);
      
            // Get the data for the current page
            const startIndex = (currentPage - 1) * perPage;
            const endIndex = startIndex + perPage;
            const pageData = data.slice(0, perPage);

            setAllImages(data);
            setLoadedImages(pageData);
            // ...
          }

        } catch (e) {
          setDisplay("none");
        }
      }

    getImages();

    async function getCollections() {
      try {
        const response = await fetch('https://www.api.kenankorn.com/images/collections/all', {
          method: 'GET',
          headers: {
            accept: 'application/json',
          },
        });

        if (response.status !== 200) {
          setCollection("error");
        }
        if (response.status === 200) {
          const data = await response.json();
          setCollection(data);
        }
      } catch (e) {
        setPackage("error");
        toast.error('Unknown error with collections!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    getCollections();
  }, []);

  const loadMoreImages = () => {
    setSkeleton(true);
    const startIndex = perPage;
    const endIndex = startIndex + 60;
  
    // Get the new set of images to be loaded
    const newImages = data.slice(startIndex, endIndex);
  
    // Combine old images with new images
    const updatedImages = [...loadedImages, ...newImages];
  
    // Update the state variables
    setLoadedImages(updatedImages);
    setPerPage(endIndex);
  };
  

  function toggleModal(e, name) {
    var id = e;
    var modalName = id;
    var name = name.replace('https://kenanphotography.s3.us-west-1.amazonaws.com/', '');
    logImageView(name);
    var modal = document.getElementById(modalName);
    modal.style.display = "block";
    window.onclick = function (event) {
      if (e === modal) {
        var elements = document.getElementsByClassName("modal");

        for (var i = 0; i < elements.length; i++) {
          elements[i].style.display = "none";
        }
      }
    };
  }

  function close_modal(e) {
    var elements = document.getElementsByClassName("modal");

    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
  }

  function esc_close(e) {
    if (e.target.className === "modal") {
      var elements = document.getElementsByClassName("modal");
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
    }
  }

  $(document).keydown(function (e) {
    if (e.keyCode === 27) {
      var elements = document.getElementsByClassName("modal");

      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
    }
  });

  function rightNext(e) {
    var modal = e.target.id;
    if (modal === "") {
      modal = e.target.parentNode.id;
    }
    var next_modal = parseInt(modal) + 1;
    close_modal(e);
    var showNext = document.getElementById(next_modal);
    showNext.style.display = "block";
  }

  function leftNext(e) {
    var modal = e.target.id;
    if (modal === "") {
      modal = e.target.parentNode.id;
    }
    var next_modal = parseInt(modal) - 1;
    close_modal(e);
    var showNext = document.getElementById(next_modal);
    showNext.style.display = "block";
  }

  function resetImages() {
    setImages("all");
    setFilter("none");
    setStateFilter("reset");
    scrollTop();
  }

  function collectionFilter(e) {
    var collectionName = e.target.id;
    setCollectionFilter(collectionName);
    setImages("collection");
    setFilter("collection");
    $('html, body').animate({
      scrollTop: $(".gallery").offset().top
    }, 1000);
    logCollection(collectionName);
  }


  function scrollTop() {
    $('html, body').animate({
      scrollTop: $(".collectionNew").offset().top
    }, 2000);
  }

  function scrollGallery() {
    $('html, body').animate({
      scrollTop: $(".gallery").offset().top
    }, 2000);
  }

  const onLoad = (index, length) => {
    load.push(index);
    if (!isSmallScreen && display) {
      if (load.length === (length - 8)) {
        setLoading(false);
        if (skeleton) {
          setSkeleton(false);
        }
      }
    } else {
      if (load.length === (length - 8)) {
        setLoading(false);
        if (skeleton) {
          setSkeleton(false);
        }
      }
    }
    setLoadedImagesCount(loadedImagesCount + 1); // Increment the count
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    const pageData = allImages.slice(startIndex, endIndex);
    setLoadedImages(pageData);
  }, [currentPage, allImages, perPage]);

  const logInteraction = async (pageNumber) => {
    const token = localStorage.getItem('token');
  
    try {
      const response = await fetch('https://www.api.kenankorn.com/interaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ action: `Viewed page ${pageNumber}` })  // action describes what kind of interaction this is
      });
  
      if (response.status === 200) {
        console.log('Button click logged successfully');
      } else {
        console.log('Failed to log button click');
      }
  
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const logImageView = async (imageName) => {
    const token = localStorage.getItem('token');
  
    try {
      const response = await fetch('https://www.api.kenankorn.com/interaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ action: `Viewed image ${imageName}` })  // action describes what kind of interaction this is
      });
  
      if (response.status === 200) {
        console.log('Image view logged successfully');
      } else {
        console.log('Failed to log image view');
      }
  
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const logCollection = async (collectionName) => {
    const token = localStorage.getItem('token');
  
    try {
      const response = await fetch('https://www.api.kenankorn.com/interaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ action: `Viewed collection ${collectionName}` })  // action describes what kind of interaction this is
      });
  
      if (response.status === 200) {
        console.log('Collection click logged successfully');
      } else {
        console.log('Failed to log collection click');
      }
  
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const handlePageChange = (action) => {
    if (action === "next") {
        scrollGallery();
      setCurrentPage((prevPage) => prevPage + 1);
      logInteraction(currentPage + 1);
      
    } else if (action === "prev" && currentPage > 1) {
        scrollGallery();
      setCurrentPage((prevPage) => prevPage - 1);
      logInteraction(currentPage - 1);
    }
  };
  

  return (
    <div className='gallery-main-wrapper'>

      {loading && data && (
        <Loader loaded={loadedImagesCount} data={perPage} />
      )}

      <Header setHeaderDisplay={setHeaderDisplay} />

      {!loading && (
        <div className="captionToggle">
          <div className="toggle">
            <FormGroup>
              <FormControlLabel onChange={() => setToggle(!toggle)} control={<Switch color='primary' />} labelPlacement="bottom" />
            </FormGroup>
          </div>
          <div className="description">
            Caption
          </div>
        </div>
      )}

      {!loading && (
        <div className="collectionNew">
          <Carousel
            showThumbs={false}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            onChange={(index) => setCurrentIndex(index)}
            renderCenterLeftControls={renderCenterLeftControls}
            renderCenterRightControls={renderCenterRightControls}
          >
            {collection.map((collection, i) => (
              <div className="collectionItem" key={i}>
                <div className="collectionPicture">
                  <img src={collection.image} alt="collection" className="imageCollection" />
                </div>
                <div className="collectionDetails">
                  <div className="nameWrapper">
                    <div className="nameCollection">{collection.name}</div>
                  </div>
                  <div className="button">
                    <div className="buttonViewCollection">
                      <button
                        className="btn viewCollection draw-border"
                        id={collection.dbname}
                        image={collection.image}
                        name={collection.name}
                        onClick={collectionFilter}
                      >
                        View Collection
                      </button>
                    </div>
                  </div>
                </div>
                {isFirstSlide && currentIndex === 0 && isSmallScreen && (
                  <div className="descriptionCollection">
                    <div className="swipe state-item">
                      <SwipeIcon className='swipe-icon'/>
                      Swipe to view more collections 
                    </div>
                    <div className="scroll state-item">
                      <SwipeDownIcon className='scroll-icon' />
                      Scroll down to view all my photos
                    </div>                                           
                  </div>
                )}
              </div>
            ))}
          </Carousel>
        </div>
      )}

      <div className="navTopGallery">
        <div className="filterMethod">
          {retrievedcountry === 'error' && (
            <ToastContainer />
          )}
          <>
            <div className="resetAll">
              {images === 'filtered' && (
                <>
                  <button className="resetImages" onClick={resetImages}><i className="resetIcon fa-solid fa-rotate-left"></i></button><span className="resetText" onClick={resetImages}>Reset</span>
                </>
              )}
              {images === 'citiesfiltered' && (
                <>
                  <button className="resetImages" onClick={resetImages}><i className="resetIcon fa-solid fa-rotate-left"></i></button><span className="resetText" onClick={resetImages}>Reset</span>
                </>
              )}
              {images === 'collection' && (
                <>
                  <button className="resetImages" onClick={resetImages}><i className="resetIcon fa-solid fa-rotate-left"></i></button><span className="resetText" onClick={resetImages}>Reset</span>
                </>
              )}
            </div>
          </>
        </div>
      </div>

      <div className={state} id="mainWrapper">
        {images === 'all' && (
          <div id="photos" style={{ display: loading ? 'none' : 'block' }}>
            {loadedImages.map((image, i) => (
              <>
                <div className="imageContainer" key={i} data-value={i} id={image.id} img-alt={image.country} img-city={image.city} onClick={e => toggleModal(i, image.file_src)} >
                  <div className="img-c">
                    <LazyLoadImage
                        onLoad={() => onLoad(i, loadedImages.length)}
                        id={image.country}
                        wrapperClassName='img-w'
                        className='imageMain'
                        alt={image.country}
                        src={image.file_src}
                        img-city={image.city}
                        visibleByDefault={"https://wtwp.com/wp-content/uploads/2015/06/placeholder-image.png"}
                      />
                    {toggle && (
                      <div className="text-overlay">
                        <div className="location">
                          {image.city}, {image.country}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                <div id={i} className="modal" style={{ display: modal }} onClick={e => esc_close(e)}>
                  <span onClick={e => close_modal(e.target.parentNode.id)} className="close">&times;</span>
                  <div className="modal-content-wrapper">
                    <img className="modal-content" id="img" src={image.file_src}></img>
                    <div className='description'>
                      <div className="iconLocation">
                        <i className="locationPin fa-solid fa-city"></i>
                        <p id="caption"> {image.city}</p>
                      </div>
                      <div className="captionDescription">
                        <p id="city"></p><i className="locationPin fa-solid fa-globe"></i>
                        <p id="country"> {image.country}</p>
                      </div>
                    </div>
                  </div>
                  <div className="slideNavigation">
                    <div className="leftSlide" id={i} onClick={e => leftNext(e)}>
                      <i className="slideIcon fa-solid fa-arrow-left"></i>
                    </div>
                    <div className="rightSlide" id={i} onClick={e => rightNext(e)}>
                      <i className="slideIcon fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
        {skeleton && (
            <LoaderSpinner />
        )}


        {images === 'error' && (
          <>
            <ToastContainer />
          </>
        )}

        {images === 'all' && (
          <>
            <div id="photos" style={{ display: 'none' }}>
              {data.map((image, i) => (
                <>
                  <div className="imageContainer" key={i} data-value={i} id={image.id} img-alt={image.country} img-city={image.city} onClick={e => toggleModal(i, image.file_src)} >
                    <div className="img-c">
                      <LazyLoadImage
                        id={image.country}
                        wrapperClassName='img-w'
                        className='imageMain'
                        alt={image.country}
                        src={image.file_src}
                        img-city={image.city}
                        visibleByDefault={"https://wtwp.com/wp-content/uploads/2015/06/placeholder-image.png"}
                      />
            </div>
            </div>
              </>
              ))}
            </div>
          </>

        )}                        

        {images === 'collection' && (
          <>
            <div id="photos">
              {data.filter(x => x.collection.includes(collectionfilter)).map((image, i) => (
                <>
                  <div className="imageContainer" data-value={i} id={image.id} img-alt={image.country} img-city={image.city} onClick={e => toggleModal(i, image.file_src)}>
                    <div className="img-c">
                      <LazyLoadImage
                        id={image.country}
                        wrapperClassName='img-w'
                        className='imageMain'
                        alt={image.country}
                        effect="blur"
                        src={image.file_src}
                        img-city={image.city}
                        visibleByDefault={"https://wtwp.com/wp-content/uploads/2015/06/placeholder-image.png"}
                      />
                      {toggle && (
                        <div className="text-overlay">
                          <div className="location">
                            {image.city}, {image.country}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id={i} className="modal" style={{ display: modal }} onClick={e => esc_close(e)}>
                    <span onClick={e => close_modal(e.target.parentNode.id)} className="close">&times;</span>
                    <div className="modal-content-wrapper">
                      <img className="modal-content" id="img" src={image.file_src}></img>
                      <div className='description'>
                        <div className="iconLocation">
                          <i className="locationPin fa-solid fa-city"></i>
                          <p id="caption"> {image.city}</p>
                        </div>
                        <div className="captionDescription">
                          <p id="city"></p><i className="locationPin fa-solid fa-globe"></i>
                          <p id="country"> {image.country}</p>
                        </div>
                      </div>
                    </div>
                    <div className="slideNavigation">
                      <div className="leftSlide" id={i} onClick={e => leftNext(e)}>
                        <i className="slideIcon fa-solid fa-arrow-left"></i>
                      </div>
                      <div className="rightSlide" id={i} onClick={e => rightNext(e)}>
                        <i className="slideIcon fa-solid fa-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </>
        )}

        {images === 'cleared' && (
          <>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <ToastContainer />
            {data.map((image, i) => (
              <div className="img-c" key={i}>
                <LazyLoadImage
                  id={image.country}
                  wrapperClassName='img-w'
                  className='imageMain'
                  alt={image.country}
                  effect="blur"
                  src={image.file_src}
                  img-city={image.city}
                  onClick={() => toggleModal(i, image.file_src)}
                  visibleByDefault={"https://wtwp.com/wp-content/uploads/2015/06/placeholder-image.png"}
                />
              </div>
            ))}
          </>
        )}
      </div>

      {images === 'all' && 
            <div className="pagination-controls">
                <button 
                    className="prev-button load-more-button" 
                    disabled={currentPage === 1} 
                    onClick={() => handlePageChange("prev")}>
                    <ArrowBackIosNewIcon /> Previous 
                </button>
                
                <button 
                    className="next-button load-more-button" 
                    disabled={currentPage * perPage >= allImages.length} 
                    onClick={() => handlePageChange("next")}>
                    Next <ArrowForwardIosIcon />
                </button>
            </div>
      }
      

       


      {!loading && data && (
        <>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Gallery;