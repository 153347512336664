import { useEffect, useState, useRef } from 'react';
import './client.scss';
import Header from "../../assets/headers/12.jpg";
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { saveAs } from 'file-saver';
import { CircularProgress } from '@mui/material';
import ReactConfetti from 'react-confetti';
import Modal from '@mui/material/Modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/system';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import InfoIcon from '@mui/icons-material/Info';

const config = {
  angle: 90,
  spread: 45,
  startVelocity: 45,
  elementCount: 70,
  decay: 0.9
};

const Client = () => {
  const downloadButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [error, setError] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [original, setOriginal] = useState('');
  const [username, setUsername] = useState('');
  const [expiration, setExpiration] = useState('');
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [open, setOpen] = useState(true);
  const [smallScreen, setSmallScreen] = useState(false);
  const [openSmall, setOpenSmall] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isSmall = window.innerWidth < 800;
      setSmallScreen(isSmall);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setOpenSmall(smallScreen);
  }, [smallScreen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    if (!expiration) {
      return {};
    }

    const difference = new Date(expiration) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      timeLeft = {
        hours: hours < 10 ? `0${hours}` : hours,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds,
      };
    }

    return timeLeft;
  }

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  const handleSmallClose = () => {
    setOpenSmall(false);
    setSmallScreen(false);
  };

  const handleMouseEnter = () => {
    if (downloadButtonRef.current) {
      downloadButtonRef.current.style.transition = 'opacity 3s';
      downloadButtonRef.current.style.opacity = 1;
    }
  };

  const handleMouseLeave = () => {
    if (downloadButtonRef.current) {
      downloadButtonRef.current.style.transition = 'opacity 3s';
      downloadButtonRef.current.style.opacity = 0;
    }
  };

  function downloadImage(url) {
    setDownloading(true);
    let imageUrl = new URL(url);
    let fileName = imageUrl.pathname.substring(1);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`https://www.api.kenankorn.com/download/${original}?filename=${encodeURIComponent(fileName)}`);
        const blob = await response.blob();
        saveAs(blob, fileName);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  function downloadAllImages() {
    setDownloading(true);

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`https://www.api.kenankorn.com/downloadAll/${original}`);
        const blob = await response.blob();
        saveAs(blob, "images.zip");
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      fetch('https://www.api.kenankorn.com/client/dashboard', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token, // send the token in the Authorization header
        },
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Not logged in');
          }
        })
        .then(data => {
          setImages(data.images);
          let username = data.username;
          setOriginal(data.username);
          //capitalize first letter
          username = username.charAt(0).toUpperCase() + username.slice(1);
          setUsername(username);
          document.title = `${username}'s Dashboard`
          const expirationDate = new Date(data.expiration);
          expirationDate.setDate(expirationDate.getDate() + 1); // Add 1 day to the expiration date
          setExpiration(expirationDate.toUTCString());
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          window.location.href = '/client/login';
        });
    } catch (error) {
      console.log(error);
      setError(true);
    }
  }, []);

  const extensionRequest = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://www.api.kenankorn.com/client/extension/${original}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setLoading(false);
        alert("Extension has been requested!");
      } else {
        setLoading(false);
        alert("Error changing access time");
      }
    } catch (error) {
      setLoading(false);
      alert("Unknown error occurred.");
      console.log(error);
    }
  };

  function scrollImages() {
    document.querySelector('.client-images').scrollIntoView({
      behavior: 'smooth'
    });
  }

  return (
        <div className='client-wrapper'>
            {success && 
            <>
                <ReactConfetti 
                    active={ success } 
                    className='confetti-success'
                />
                <div className='success-modal'>
                        <Modal
                        open={success}
                        onClose={handleClose}
                        aria-labelledby="success-modal-title"
                        aria-describedby="success-modal-description"
                        className='success-modal'
                        >
                        <Box className="modalBox">
                            <CheckCircleIcon className="checkIcon" />
                            <h2 className="success-modal-title">Image(s) downloaded successfully!</h2>
                            <p className="success-modal-description">Enjoy!!!</p>
                        </Box>
                        </Modal>
                </div>
                </>
            }
           
            {loading && 
                <div className="loading-client">
                    <CircularProgress />
                </div>
            }
            {downloading && 
                <div className="loading-download">
                    <CircularProgress />
                    <div className="loading-text">
                        Downloading...
                    </div>
                </div>
            }
            {!loading && 
                <div className="client-header">
                    <div className="client-header-img">
                        <img src={Header} alt="Client Header" className="client-header-image" />
                    </div>
                    <div className="client-text">
                        <div className="client-smaller">
                            <div className="client-title">Welcome, <span className="name">{username}</span></div>
                            <p className="client-header-subtitle">
                                You can view your images here. You will have <b></b> to {timeLeft.hours ? `${timeLeft.hours} hours ` : null}
                                    {timeLeft.minutes ? `${timeLeft.minutes} minutes ` : null}
                                    {timeLeft.seconds ? `${timeLeft.seconds} seconds` : "0 seconds"} to download and view the images before they are deleted and client access is terminated. <div className='wrapper-request'>Request an extension: <div className="extension" onClick={extensionRequest}>here</div></div>
                                <br></br>
                                Hover/tap to download individual images.
                            </p>
                            <div className="actions">
                                <button className="download-button" onClick={() => 
                                {
                                    downloadAllImages().then(() => {
                                        setSuccess(true);
                                        setDownloading(false);
                                    }).catch((error) => {
                                        console.log(error);
                                    });
                                } 
                                }>
                                    Download <DownloadIcon />
                                </button>
                                <button className="scroll-btn-client" onClick={scrollImages}>
                                    Images <VisibilityIcon />
                                </button>
                            </div>
                            <div className="timer">
                                <div className="timer-text">
                                    {timeLeft.hours ? `${timeLeft.hours} hours ` : null}
                                    {timeLeft.minutes ? `${timeLeft.minutes} minutes ` : null}
                                    {timeLeft.seconds ? `${timeLeft.seconds} seconds` : "0 seconds"}
                                </div>
                                
                                <div className="subtitle" id="subtitle">
                                    Until images are deleted
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            }
            
            <div className="client-images">
                {!loading &&
                    <>
                        <div className="image-grid">
                            {images.map((image, i) => (
                                <div className="image-wrapper-client"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div className="client-image-img">
                                        <LazyLoadImage
                                            wrapperClassName='client-image-img'
                                            className='client-image-img'
                                            src={images[i]}
                                            visibleByDefault={"https://wtwp.com/wp-content/uploads/2015/06/placeholder-image.png"}
                                        />
                                    </div>
                                    <div className="download-button-client">
                                        <button
                                            ref={downloadButtonRef}
                                            className="download-button-btn"
                                            onClick={() => 
                                                {
                                                    downloadImage(image).then(() => {
                                                        setSuccess(true);
                                                        setDownloading(false);
                                                    }).catch((error) => {
                                                        console.log(error);
                                                    });
                                                } 
                                            }
                                        >
                                            <DownloadIcon
                                                fontSize='large'
                                                className='download-icon'
                                            />
                                        </button>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </>
                }

            </div>
        </div>
    )
}

export default Client