import React from 'react'
import './newnav.scss'
import $ from 'jquery'; 
import jQuery from 'jquery';
import {useState, useEffect} from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
export default function Newnav() {
    const [admin, setAdmin] = useState("false");
    const [inuse, setUse] = useState("none");
    useEffect(()=>{
        const checkCookie = cookies.get('adminCookie');
        if (checkCookie){
            setAdmin("true")
        }
        if(document.URL.indexOf("blog") >= 0){ 
            var change = document.getElementById("blogLink");
            change.classList.add("activeIcon");
            }
        if(document.URL.indexOf("rankings") >= 0){ 
            var change = document.getElementById("rankingLink");
            change.classList.add("activeIcon");
            }
        if(document.URL.indexOf("gear") >= 0){ 
            var change = document.getElementById("gearLink");
            change.classList.add("activeIcon");
            }
        if(document.URL.indexOf("/collections") >= 0){ 
            var change = document.getElementById("rankingLink");
            change.classList.add("activeIcon");
            }
        if(document.URL.indexOf("/admin") >= 0){ 
            setAdmin("active")
            }
        var current = window.location.pathname;
        if (current == '/') {
            var change = document.getElementById("navlink");
            change.classList.add("activeIcon");
        }        

    }, [])
    
    function logOut() {
        setAdmin("logout")
        document.cookie = "adminCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.href = "/home"
    }
  return (
    <div className='navWrapper'>
        <div className="siteHeaderLeft">
            <h1 id='navlink'>Kenan's Photography</h1>
        </div>
        {/*
        <div className="navbarLinks">
            <ul>
                <li><a href="/blog" id='blogLink' className='link'>Blog</a></li>
                <li><a href="/rankings" id='rankingLink' className='link'>Rankings</a></li>
                <li><a href="/gear" id='gearLink' className='link'>Gear</a></li>
                {admin === 'true' && 
                    <>
                     <li><a href="/admin" id='adminLink' className='link'>Admin</a></li>
                    <i className="logOut fa-solid fa-arrow-right-from-bracket" onClick={logOut}></i>
                    </>
                }
                {admin === 'active' && 
                    <>
                     <li><a href="/admin" id='adminLink' className='link activeIcon'>Admin</a></li>
                    <i className="logOut fa-solid fa-arrow-right-from-bracket" onClick={logOut}></i>
                    </>
                }
            </ul>
        </div>
         */}
    </div>
    
  )
}

