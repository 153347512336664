import { useState, useEffect } from 'react'
import './marceline.scss'
import marcelinePicture from "../../assets/marceline.jpg"
import Cookies from 'universal-cookie'
import Marceline from './Marceline'

const Entry = () => {
    const [show, setShow] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isLogin, setIsLogin] = useState(false)
    
    useEffect(() => {
        const cookies = new Cookies()
        const marcelineCookie = cookies.get('marceline')
        if (marcelineCookie === 'true') {
            setIsLogin(true)
        }
    }, [])

    const handleLogin = () => {
        if (username === 'marceline' && password === 'vampirequeen') {
            setIsLogin(true)
            const cookies = new Cookies()
            cookies.set('marceline', 'true', { path: '/' })
        }
    }
    return (
    <div className="wrapper-marceline">
        {!isLogin &&
            <div className="login-marceline">
                <div className='login-form-marceline'>
                    <form>
                        <div className="title">
                            <h1 className='title-main'>👋</h1>
                            <img src={marcelinePicture} alt="Marceline" className='profile-image'/>
                        </div>
                        <div className='form-item'>
                            <span htmlFor='username'>Username</span>
                            <input type='text' id='username' className='marceline-input' value={username} onChange={(e) => setUsername(e.target.value)} placeholder='Enter your username'/>
                        </div>
                        <div className='form-item'>
                            <span htmlFor='password'>Password</span>
                            <input type='password' id='password' className='marceline-input' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Enter your password'/>
                        </div>
                        <button type='submit' className='marceline-submit' onClick={(e) => {
                            e.preventDefault()
                            handleLogin()
                        }}>Log in</button>
                    </form>
                </div>
                
            </div>
        }
        
        {isLogin &&
            <Marceline />
        }
    </div>
    
    
  )
}

export default Entry