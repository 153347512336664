import React from 'react'
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Return from '../navbar/Return';
export default function Blogedit() {
    const [data, setData] = useState([]);
    const [postcount, setPost] = useState(5);
    const [deleted, setDeleted] = useState("none");
    useEffect(() => {
        async function getBlog() {
            try {
                const response = await fetch('https://www.api.kenankorn.com/blog', {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                    },
                    });
                    if (response.status != 200) {
                        setData('Error');
                    }
                    if (response.status == 200) {
                        const data = await response.json();
                        setData(data);
                    }
            }catch(e){
                setData('error')
                }
            }
            getBlog()
    }, [])
    function checkLocation(e) {
        const coordinates = e.target.id
        const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + coordinates + "&sensor=&key=AIzaSyBXJAjDQI_dE2_-hi5RA05tWT4med-zeXs"
        async function getData() {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                },
            });
            const data = await response.json();
            const city = data.plus_code.compound_code
            var locationDiv = document.getElementById("locationShow");
            locationDiv.innerHTML = '<i className="fa-solid fa-location-crosshairs"></i> ' + city;
        }
        getData()
    }
    function editPost(e) {
        var postID = e.target.id
        window.location.href = '/edit/' + postID
    }
    function deletePost(e) {
        var postid = e.target.id
        var div = e.target.parentNode;
        div.style.display = "none";
        let body_data = {
            "id": postid,
        }
        async function deletePostData() {
            const response = await fetch("https://www.api.kenankorn.com/deletepost", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            toast.success('Deleted post!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deletePostData()
    }
  return (
    <div className='viewsWrapper'>
        <div className="collectionsHeader">
            <img src="https://i.natgeofe.com/n/737e4cbf-3d9e-4f89-b4b2-29b5cf303dba/01-best_pod_landscapes.jpg?w=636&h=424" className='collectionsImage' id='headingImage' alt="header"></img>
            <div className="centered">
                <h1 className="headerCollections">See Blog Data</h1>
                <div className="subheaderCollections">
                    Edit/Remove blog posts
                </div>
            </div>
        </div>
        <br></br>
            <>
            <table>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Location</th>
                    <th>Date</th>
                </tr>
                <></>
            {deleted === 'none' &&
                <>
                    {data.slice(0,postcount).map((blog, i) =>
                            <tr key={i}>
                                <td className='postID'>{blog.id}</td>
                                <td className='locationClass'>{blog.title}</td>
                                <td className='locationClass'>{blog.city}, {blog.country}</td>
                                <td>{blog.date}</td>
                                <i className="deleteIcon fa-solid fa-trash" id={blog.id} onClick={deletePost}></i>
                                <i className="editIcon fa-solid fa-pen-to-square" id={blog.id} onClick={editPost}></i>
                            </tr>
                        )}
                </>
            }
            {deleted === 'newdata' &&
                <>
                    <ToastContainer />
                    {data.slice(0,postcount).map((blog, i) =>
                            <tr key={i}>
                                <td className='postID'>{blog.id}</td>
                                <td className='locationClass'>{blog.title}</td>
                                <td className='locationClass'>{blog.city}, {blog.country}</td>
                                <td>{blog.date}</td>
                                <i className="deleteIcon fa-solid fa-trash" id={blog.id} onClick={deletePost}></i>
                                <i className="editIcon fa-solid fa-pen-to-square" id={blog.id} onClick={editPost}></i>
                            </tr>
                        )}
                </>
            }
            </table>
            <br></br>
            </>
    </div>
  )
}

