import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Collapse, Box, TextField, Select, MenuItem } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import './views.scss'
import Loader from '../loader/Loader';

const PageVisitsTable = () => {
    const [pageVisits, setPageVisits] = useState([]);
    const [statistics, setStatistics] = useState([]);  // For the bar chart
    const [openRow, setOpenRow] = useState(null);  // To keep track of the open row
    const [search, setSearch] = useState(''); // For search functionality
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const [visitFrequency, setVisitFrequency] = useState([]);
    const [imageFrequency, setImageFrequency] = useState([]);
    const [visitByCity, setVisitByCity] = useState([]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const res = await fetch('https://www.api.kenankorn.com/userdata');
                const data = await res.json();

                // Merging interactions into locations based on matching IDs
                const mergedData = data.userData.map(user => {
                    const userInteractions = user.interactions.filter(int => int.session_id === user.user_id);
                    return {
                        ...user,
                        locations: user.locations.map(loc => ({
                            ...loc,
                            interactions: userInteractions
                        }))
                    };
                });

                setStatistics(data.statistics); 

                setPageVisits(mergedData);
                const visitFrequencyData = data.userData.reduce((acc, curr) => {
                    curr.locations.forEach(loc => {
                        const parsed_date = convertToLocalTime(loc.time_accessed);
                        const date = new Date(parsed_date.split(' ')[0]).toDateString();// Convert to local date string (ignores time)
                        acc[date] = (acc[date] || 0) + 1;  // Increment the count for that date
                    });
                    return acc;
                }, {});
                // Convert the accumulated data into an array format suitable for recharts
                const visitFrequencyArray = Object.entries(visitFrequencyData).map(([date, count]) => ({date, count}));
                // reverse array 
                setVisitFrequency(visitFrequencyArray.reverse());
                const imageFrequencyData = mergedData.reduce((acc, user) => {
                    user.locations.forEach(loc => {
                        loc.interactions.forEach(int => {
                            const imageUrl = int.action.split(' ').pop();
                            acc[imageUrl] = (acc[imageUrl] || 0) + 1;
                        });
                    });
                    return acc;
                }, {});
                
                const imageFrequencyArray = Object.entries(imageFrequencyData).map(([image, count]) => ({ image, count }));
                setImageFrequency(imageFrequencyArray);

                const visitsByCity = data.userData.reduce((acc, curr) => {
                    curr.locations.forEach(loc => {
                        const cityName = loc.city;
                        acc[cityName] = (acc[cityName] || 0) + 1;
                    });
                    return acc;
                }, {});
                const visitsByCityArray = Object.entries(visitsByCity).map(([name, value]) => ({ name, value }));
                setVisitByCity(visitsByCityArray);                
                setLoading(false);
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };
        fetchData();
        
    }, []);

    const handleBarClick = (data) => {
        console.log(data);
        if (data && data.name || data.date) {
            if (data.date) {
                // convert date to utc format
                const date = new Date(data.date);
                const utcDate = date.toISOString();
                data.name = utcDate;
            }
            console.log(data.name)
            setSearch(data.name);
        }
    };
    

    const convertToLocalTime = (utcDateTime) => {
        let date = new Date(utcDateTime);  // Create date object directly from the string
        return date.toLocaleString();  // Convert to local time
    };
    const utcConverter = (utcDateTime) => {
        const date = new Date(utcDateTime);
        const localDate = date.toLocaleString("en-US", { timeZone: "America/Los_Angeles" });  // Replace this with your time zone
        return localDate;
    }

    const filteredVisits = pageVisits.filter((row) => {
        const lowerCaseSearch = search.toLowerCase();
    
        const includesCaseInsensitive = (value, searchString) => {
            return value && typeof value === 'string' && value.toLowerCase().includes(searchString);
        };
        
    
        const matchesSearch =
            includesCaseInsensitive(row.locations, lowerCaseSearch) ||
            (row.locations && row.locations.some((loc) => includesCaseInsensitive(loc.device_model, lowerCaseSearch))) ||
            (row.locations && row.locations.some((loc) => includesCaseInsensitive(loc.city, lowerCaseSearch))) ||
            (row.locations && row.locations.some((loc) => includesCaseInsensitive(loc.country, lowerCaseSearch))) ||
            (row.locations && row.locations.some((loc) => includesCaseInsensitive(loc.ip_address, lowerCaseSearch))) ||
            (row.locations && row.locations.some((loc) => includesCaseInsensitive(loc.user_agent, lowerCaseSearch))) ||
            (row.interactions && row.interactions.some((loc) => includesCaseInsensitive(loc.action, lowerCaseSearch))) ||
            (row.locations && row.locations.some((loc) => includesCaseInsensitive(loc.country, lowerCaseSearch)));
    
        const matchesFilter = !filter || (row.locations && row.locations.some((location) => location.device_type === filter));
    
        return matchesSearch && matchesFilter;
    });
    



    const toggleRow = (index) => {
        if (openRow === index) {
            setOpenRow(null);
        } else {
            setOpenRow(index);
        }
    };

    return (
        <div style={{ padding: '2rem', maxWidth: '1000px', margin: '0 auto', justifyContent: 'center' }}>
            {loading && <div className="loading"><Loader /></div>}
            {!loading && 
            <>
            <h1 className='page-visit-header'>Page Visits</h1>
            <div  className='box-wrapper'>
                <Typography variant="h6" className='data-box'>Total Visits<span className="results">{statistics.totalVisits}</span></Typography>
                <Typography variant="h6" className='data-box'>Total Interactions<span className="results">{statistics.totalInteractions}</span></Typography>
                <Typography variant="h6" className='data-box'>Return Visits<span className="results">{statistics.returnVisits}</span></Typography>
            </div>

            <div className="graphs">
                {statistics && statistics.visitsByLocation && 
                <>
                <div className="graph-wrapper">
                    <Typography variant="h6" style={{ marginBottom: '1rem' }}>Visits By Location</Typography>
                    <BarChart width={500} height={300} className='graph' data={visitByCity}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="value" fill='#3D70F4' onClick={handleBarClick}/>
                    </BarChart>
                </div>
                    
                </>
                }
                {!loading && visitFrequency && visitFrequency.length > 0 &&
                <>
                <div className="graph-wrapper">
                    <Typography variant="h6" style={{ marginBottom: '1rem' }}>Visit Frequency Over Time</Typography>
                        <BarChart width={600} height={300} data={visitFrequency}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="count" fill='#8884d8' name="Visits" fill='#3D70F4' onClick={handleBarClick}/>
                        </BarChart>
                </div>
                   
                </>
                }
                {!loading && imageFrequency && imageFrequency.length > 0 &&
                <div className="graph-wrapper">
                    <Typography variant="h6" style={{ marginBottom: '1rem' }}>Interactions</Typography>
                    <BarChart width={600} height={300} className='graph' data={imageFrequency}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="image" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" fill='#3D70F4' onClick={handleBarClick}/>
                    </BarChart>
                </div>
                    
                
                }
            </div>
            
            
            <div className="search-wrapper-views">
                <input type="text" className='search-bar-views' onChange={(e) => setSearch(e.target.value)} placeholder='Search' />
                <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="desktop">Desktop</MenuItem>
                    <MenuItem value="mobile">Mobile</MenuItem>
                    <MenuItem value="tablet">Tablet</MenuItem>
                </Select>
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left'>View</TableCell>
                            <TableCell align="left">IP Address</TableCell>
                            <TableCell align="left">Device Type</TableCell>
                            <TableCell align="left">Device Model</TableCell>
                            <TableCell align="left">Browser</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredVisits.map((row, index) => (
                            <React.Fragment key={index}>
                                <TableRow onClick={() => toggleRow(index)} style={{ cursor: 'pointer' }}>
                                    <TableCell align='center' className='border-none-cell'>{openRow === index ? <ArrowCircleUpIcon /> : <ExpandCircleDownIcon />}</TableCell>
                                    <TableCell align="left">{row.locations && row.locations.length > 0 ? row.locations[0].ip_address : 'N/A'}</TableCell>
                                    <TableCell align="left">{row.locations && row.locations.length > 0 ? row.locations[0].device_model : 'N/A'}</TableCell>
                                    <TableCell align="left">{row.locations && row.locations.length > 0 ? row.locations[0].browser_name : 'N/A'}</TableCell>
                                    <TableCell align="left">{row.locations && row.locations.length > 0 ? row.locations[0].user_agent : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                        <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    User Agents
                                                </Typography>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>User Agent</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(row.locations || []).map((user_agent, i) => (
                                                            <TableRow key={i}>
                                                                <TableCell>{user_agent.user_agent}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    Interactions
                                                </Typography>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Action</TableCell>
                                                            <TableCell>Time</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(row.interactions || []).map((interaction, i) => (
                                                            <TableRow key={i}>
                                                                <TableCell>{interaction.action}</TableCell>
                                                                <TableCell>{utcConverter(interaction.created_at)}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    Geolocation
                                                </Typography>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>City</TableCell>
                                                            <TableCell>Country</TableCell>
                                                            <TableCell>Latitude</TableCell>
                                                            <TableCell>Longitude</TableCell>
                                                            <TableCell>Time</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(row.locations || []).map((location, i) => (
                                                            <TableRow key={i}>
                                                                <TableCell>{location.city}</TableCell>
                                                                <TableCell>{location.country}</TableCell>
                                                                <TableCell>{location.latitude}</TableCell>
                                                                <TableCell>{location.longitude}</TableCell>
                                                                <TableCell>{convertToLocalTime(location.time_accessed)}</TableCell> {/* Conversion happens here */}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
            }

        </div>
    );
};

export default PageVisitsTable;
