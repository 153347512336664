import React from 'react'
import './upload.scss'
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Return from '../navbar/Return';
export default function Upload() {
    const [success, setSuccess] = useState("none");
    const [loading, setLoading] = useState("none");
    const [file, setFile] = useState();
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [collection, setCollection] = useState("");
    function showPreview(e) {
        var imageInput = document.getElementById("imageInput");
        var image = document.getElementById("previewImage");
        var size = document.getElementById("imageSize");
        const [imageFile] = imageInput.files
        if (imageFile) {
            image.src = URL.createObjectURL(imageFile)
            size.innerHTML = imageFile.size + " mb"
        }
        setFile(e.target.files[0]);
    }

    function setURLPreview(e) {
        var image = document.getElementById("previewImage");
        var size = document.getElementById("imageSize");
        image.src = e
        size.innerHTML = "unknown"
        setFile(e);
    }

    let handleSubmit = async (e) => {
        setSuccess("loading")
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('city', city);
        formData.append('country', country);
        formData.append('collection', collection);
        formData.append('file', file);
        try {
            let res = await fetch("https://www.api.kenankorn.com/uploadfile", {
                method: "POST",
                body: formData,
        })
        let resJson = await res.json();
        if (res.status === 200) {
            showSuccess()
        } else {
            showError()
        }
        } catch (err) {
            showError()
        }
    };
    function showSuccess() {
        setSuccess("success")
        toast.success('Successfully posted!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    function showError() {
        setSuccess("error")
        toast.error('Error uploading image!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    function clearForm() {
        var form = document.getElementById("uploadForm");
        form.reset();
        setSuccess("")
        var image = document.getElementById("previewImage");
        image.reset();
    }

  return (
    <div className='uploadWrapper'>
        <div className="uploadForm">
            <img src="https://archive.org/download/placeholder-image/placeholder-image.jpg" alt="preview" id='previewImage' className='previewImage'/>
            <span className="fileDescriptor">File size: </span><span className="imageSize" id='imageSize'></span>
            <br></br><br></br>
            <form onSubmit={handleSubmit} className='upload' id='uploadForm'>
                <input type="file" name="file" accept='image/*' className='fileUpload' id='imageInput' onChange={showPreview}/>
                <br></br><br></br>
                <input type="text" className="inputUpload" name='file' placeholder='Image URL' onChange={(e) => setURLPreview(e.target.value)}/>
                <br></br><br></br>
                <input type="text" className="inputUpload" name='name' placeholder='Image name' onChange={(e) => setName(e.target.value)}/>
                <br></br><br></br>
                <input type="text" className="inputUpload" name='city' placeholder='Image city' onChange={(e) => setCity(e.target.value)}/>
                <br></br><br></br>
                <input type="text" className="inputUpload" name='country' placeholder='Image country' onChange={(e) => setCountry(e.target.value)}/>
                <br></br><br></br>
                <input type="text" className="inputUpload" name='collection' placeholder='Collection name' onChange={(e) => setCollection(e.target.value)}/>
                <br></br><br></br>
                <input type="submit" value="Upload" className='submitUpload'/>
            </form> 
        </div>
       
        {success === 'loading' &&
             <div className="loadingShow">
                File is uploading
                <br></br>
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        }
        {success === 'success' &&
            <>
            <ToastContainer />
            <div className="successDiv">
                <i className="checkMark fa-solid fa-circle-check"></i>Your image has been uploaded!
                <br></br>
                <button className="clearForm" onClick={clearForm}>Upload Another</button>
            </div>
            </>
        }
        {success === 'error' &&
            <>
            <ToastContainer />
            <div className="successDiv">
                <i className="checkMark fa-solid fa-x-mark"></i>Error uploading image.
            </div>
            </>
        }
    </div>
  )
}


