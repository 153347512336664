import React from 'react'
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Return from '../navbar/Return';
import Writerankings from '../writerankings/Writerankings';
export default function Blogedit() {
    const [destinations, setDestinations] = useState([]);
    const [main, setMain] = useState([]);
    const [rating, setRemoveRating] = useState("none");
    const [content, setContent] = useState("none");
    const [deleted, setDeleted] = useState("none");
    useEffect(() => {
        async function getBlog() {
            try {
                const response = await fetch('https://www.api.kenankorn.com/rankings/destinations', {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                    },
                    });
                    if (response.status != 200) {
                        setDestinations('Error');
                    }
                    if (response.status == 200) {
                        const data = await response.json();
                        setDestinations(data);
                    }
            }catch(e){
                setDestinations('error')
                }
            }
            getBlog()
            async function getMain() {
                try {
                    const response = await fetch('https://www.api.kenankorn.com/return/topfiveall', {
                        method: 'GET',
                        headers: {
                            accept: 'application/json',
                        },
                        });
                        if (response.status != 200) {
                            setMain('Error');
                        }
                        if (response.status == 200) {
                            const data = await response.json();
                            setMain(data);
                        }
                }catch(e){
                    setMain('error')
                    }
                }
            getMain()
    }, [])

    function editPost(e) {
        var postID = e.target.id
        window.location.href = '/edit/' + postID
    }
    function deleteRating(e) {
        var postid = e.target.id
        let body_data = {
            "id": postid,
        }
        async function deletePostData() {
            const response = await fetch("https://www.api.kenankorn.com/deleteranking", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            setDestinations(refresheddata)
            setRemoveRating("newdata")
            toast.success('Deleted post!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deletePostData()
    }
    function deletePost(e) {
        var postid = e.target.id
        let body_data = {
            "id": postid,
        }
        async function deletePostData() {
            const response = await fetch("https://www.api.kenankorn.com/admin/deletemain", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            setMain(refresheddata)
            setContent("newdata")
            toast.success('Deleted post!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deletePostData()
    }

  return (
    <div className='viewsWrapper'>
        <div className="collectionsHeader">
            <img src="https://img.delicious.com.au/N4HZuhK0/del/2018/12/seljalandsfoss-iceland-98669-2.jpg" className='collectionsImage' id='headingImage' alt="header"></img>
            <div className="centered">
                <h1 className="headerCollections">See Destinations Data</h1>
                <div className="subheaderCollections">
                    Write and check destinations info!
                </div>
            </div>
        </div>
        <br></br>
            <>
            <>
                <Writerankings />
            <>
            <br></br>
            <table>
                <tr>
                    <th>ID</th>
                    <th>Destination</th>
                    <th>Rating</th>
                </tr>
                <></>
                {rating === 'none' &&
                    <>
                        {destinations.map((destination, i) =>
                                <tr key={i}>
                                    <td className='postID'>{destination.id}</td>
                                    <td className='locationClass'>{destination.destination}</td>
                                    <td className='locationClass'>{destination.rating}</td>
                                    <i className="deleteIcon fa-solid fa-trash" id={destination.id} onClick={deleteRating}></i>
                                    <i className="editIcon fa-solid fa-pen-to-square" id={destination.id} onClick={editPost}></i>
                                </tr>
                            )}
                    </>
                }
                {rating === 'newdata' &&
                    <>
                        <ToastContainer />
                        {destinations.map((destination, i) =>
                                <tr key={i}>
                                    <td className='postID'>{destination.id}</td>
                                    <td className='locationClass'>{destination.destination}</td>
                                    <td className='locationClass'>{destination.rating}</td>
                                    <i className="deleteIcon fa-solid fa-trash" id={destination.id} onClick={deleteRating}></i>
                                    <i className="editIcon fa-solid fa-pen-to-square" id={destination.id} onClick={editPost}></i>
                                </tr>
                            )}
                    </>
                }
                </table>
                <br></br>
                <table>
                <tr>
                    <th>ID</th>
                </tr>
                <></>
                {content === 'none' &&
                    <>
                        {main.map((destination, i) =>
                                <tr key={i}>
                                    <td className='postID'>{destination.id}</td>
                                    <i className="deleteIcon fa-solid fa-trash" id={destination.id} onClick={deletePost}></i>
                                </tr>
                            )}
                    </>
                }
                {content === 'newdata' &&
                    <>
                        <ToastContainer />
                        {main.map((destination, i) =>
                                <tr key={i}>
                                    <td className='postID'>{destination.id}</td>
                                    <i className="deleteIcon fa-solid fa-trash" id={destination.id} onClick={deletePost}></i>
                                </tr>
                            )}
                    </>
                }
                </table>
                <br></br>
                </>
            </>
            </>
    </div>
  )
}

