// AdminLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Adminnav from '../components/navbar/Adminnav';

const AdminLayout = () => {
  return (
    <div style={{ display: 'flex' }}>
      <Adminnav />
      <div style={{ flex: 1}}>
        <Outlet />  {/* This is where your routed components will render */}
      </div>
    </div>
  );
};

export default AdminLayout;
