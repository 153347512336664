import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import { Delete } from '@mui/icons-material';
import { Modal, Backdrop, Fade } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Select, MenuItem } from '@mui/material';
import { CircularProgress } from '@mui/material';

const Dashboard = () => {
    const [clients, setClients] = useState([]);
    const [logs, setLogs] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTime, setSelectedTime] = useState('24');
    const [showTime, setShowTime] = useState(false)
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState([])

    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
      };

    //show time function 
    const show = () => {
        setShowTime(!showTime);
      }; 

    const handleExtension = async (username) => {
        try {
            const response = await fetch(`https://www.api.kenankorn.com/client/extend/${username}/${selectedTime}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                alert("User updated successfully")
            } else {
                console.log("Error changing access time")
                alert("Error changing access time")
            }
        } catch (error) {
            console.log(error)
        }
    }

  useEffect(() => {
    setLoading(true);
    const fetchClients = async () => {
      try {
        const response = await fetch('https://www.api.kenankorn.com/admin/clients');
        const data = await response.json();
        setClients(data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    const fetchLogs = async () => {
      try {
        const response = await fetch('https://www.api.kenankorn.com/admin/logs');
        const data = await response.json();
        setLogs(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching logs:', error);
        setLoading(false);
      }
    };

    const fetchLocations = async () => {
        try {
            const response = await fetch('https://www.api.kenankorn.com/admin/locations');
            const data = await response.json();
            setLocations(data);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching logs:', error);
            setLoading(false);
          }
    };

    fetchClients();
    fetchLogs();
    fetchLocations();
  }, []);
  const filteredClients = clients.filter((client) =>
    client.username.toLowerCase().includes(searchQuery.toLowerCase())
    );
    
const deleteUser = async (username) => {
    setLoading(true);
    try {
        const response = await fetch(`https://www.api.kenankorn.com/client/${username}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const data = await response.json();
            console.log(data);
            alert("User deleted successfully")
        } else {
            console.log("Error deleting user")
            alert("Error deleting user!")
        }
        setLoading(false);
    } catch (error) {
        console.log(error)
        setLoading(false);
    }
};

  const handleViewLogs = (client) => {
    setSelectedClient(client);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return  (
    <div className="dashboard-wrapper-client">
        {loading && 
            <div className="loading-client">
                <CircularProgress />
            </div>
        }
      <h2 className="clients-header">Clients</h2>
      <div className="subtitle-header">Monitor client activity and logs</div>
      <div className="search-bar">
        <input
          type="text"
          className="search-user"
          placeholder="Search for user"
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className="client-grid">
         <div className="client-top">
            <div className="username">Username</div>
            <div className="action-info">
                Logs
            </div>
            <div className="delete-info">
                Delete a user
            </div>
            </div>
        {filteredClients.map((client) => (
            <div key={client.id} className="client-item">
            <div className="username">{client.username}</div>
            <button className="logs-button" onClick={() => handleViewLogs(client)}>
                View Logs
            </button>
            <div className="actions">
                <div className="delete-button" onClick={() => deleteUser(client.username)}>
                    <Delete />
                </div>
            </div>
            
            </div>
        ))}
        </div>
        <div className="all-images">
            <div className="client-grid-images">
                {filteredClients.map((client, i) => {
                    const images = JSON.parse(client.images);
                    return images.map((image, j) => (
                    <img src={image} alt="" className="client-img" key={j} />
                    ));
                })}
            </div>
            
        </div>


      {/* Modal */}
        <Modal
        open={showModal}
        onClose={handleCloseModal}
        closeAfterTransition
 
        className="modal"
      >
        <Fade in={showModal}>
          <div className="modal-content-dashboard">
            <h1 className="modal-title">
                {selectedClient?.username}'s Logs
            </h1>
            <div className="logs-wrapper">
                <div className="log-title">
                    User Logs
                </div>
            {logs.length >= 1 && logs.filter((log) => log.user_id === selectedClient?.id)
                .map((log) => (
                  <div key={log.id} className='log'>
                    <span className="timestamp">{new Date(log.timestamp).toLocaleString()}</span>: <b>{log.action}</b>
                  </div>
                ))}
            </div>
            <br></br>
            <div className="logs-wrapper-locations">
                <div className="log-title">
                    User Locations
                </div>
            {locations.length >= 1 && locations.filter((log) => log.session_id == selectedClient?.id)
                .map((log) => (
                  <div key={log.id} className='log'>
                    <span className="location">{log.city}, {log.country}</span>
                  </div>
                ))}
            </div>
            <br></br>
            <p>Password: <p className="password"><b>{selectedClient?.password}</b></p></p>
            <p>Access Date: <b>{new Date(selectedClient?.accessed_at).toLocaleString()}</b></p>
            <p>
            Expiration Date:{' '}
            <b>{selectedClient &&
                new Date(new Date(selectedClient.accessed_at).getTime() + 24 * 60 * 60 * 1000).toLocaleString()}</b>
            
            </p>
            <div className="button-time-wrapper">
                <button className="extend-time" onClick={show}>
                    <AccessTimeIcon />
                </button>
                {showTime && 
                <>
                    <div className="buttons">
                            <Select value={selectedTime} onChange={handleTimeChange}>
                                <MenuItem value={24}>24 hours</MenuItem>
                                <MenuItem value={48}>48 hours</MenuItem>
                                <MenuItem value={72}>72 hours</MenuItem>
                            </Select>
                    </div>
                    <div className="submit-button">
                        <button className="submit-btn" onClick={() => handleExtension(selectedClient.username)}>
                            Submit
                        </button>
                    </div>
                </>

                }
               
            </div>
            
            <button className="close-modal" onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </Fade>
      </Modal>

    </div>
  );
};

export default Dashboard;
